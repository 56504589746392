<template>
  <div class="page-space-ships">
    <!-- Sidebar -->
    <Sidebar />

    <!-- Space Ships -->
    <section id="space-ships" class="space-ships">
      <!-- Main Jumbotron -->
      <div class="h-100 d-flex flex-column justify-content-between">
        <!-- Navbar -->
        <Navbar />

        <main v-if="spaceShips.length">
          <div
            ref="spaceShipsSwiper"
            v-swiper:spaceShipsSwiper="swiperSpaceShipsOptions"
            @transitionEnd="spaceShipChanged"
            class="wrap-space-ships"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                :key="`spaceship-${i}`"
                v-for="(spaceShip, i) in spaceShips"
              >
                <div class="container mt-5 mt-md-0 h-md-100">
                  <div class="row justify-content-center d-none d-md-flex">
                    <div class="col d-flex justify-content-center img">
                      <img
                        :src="`${urlImg}${spaceShip.image}`"
                        :alt="spaceShip.name"
                      />
                    </div>
                  </div>

                  <div class="row align-items-center h-md-100">
                    <div class="col-md-6 name">
                      <h5
                        data-en="Let’s Introduce To,"
                        data-id="Mari Perkenalkan,"
                        class="text-uppercase"
                      ></h5>
                      <h1 class="text-uppercase">
                        {{ spaceShip.name }}
                      </h1>
                    </div>
                  </div>

                  <div class="row justify-content-center d-md-none">
                    <div class="col d-flex justify-content-center img">
                      <img
                        :src="`${urlImg}${spaceShip.image}`"
                        :alt="spaceShip.name"
                      />
                    </div>
                  </div>
                </div>

                <div class="wrap-subtitle">
                  <a
                    @click.prevent="openModal(i, 'about', 'tentang')"
                    class="subtitle d-flex align-items-center mb-3"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        mr-2
                      "
                    >
                      01
                    </div>
                    <span data-en="About" data-id="Tentang" class="mr-2"
                      >About</span
                    >
                    {{ spaceShip.name }}
                  </a>

                  <a
                    @click.prevent="openModal(i, 'producer', 'pembuat')"
                    class="subtitle d-flex align-items-center mb-3"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        mr-2
                      "
                    >
                      02
                    </div>
                    <span data-en="Producer" data-id="Pembuat" class="mr-2"
                      >Producer</span
                    >
                    {{ spaceShip.name }}
                  </a>

                  <a
                    @click.prevent="openModal(i, 'mission', 'misi')"
                    class="subtitle d-flex align-items-center mb-3"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        mr-2
                      "
                    >
                      03
                    </div>
                    <span data-en="Mission" data-id="Misi" class="mr-2"
                      >Mission</span
                    >
                    {{ spaceShip.name }}
                  </a>

                  <a
                    @click.prevent="openModal(i, 'release', 'tahun rilis')"
                    class="subtitle d-flex align-items-center"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        mr-2
                      "
                    >
                      04
                    </div>
                    <span
                      data-en="Release Year"
                      data-id="Tahun Rilis"
                      class="mr-2"
                      >Release Year</span
                    >
                    {{ spaceShip.name }}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center mt-3">
            <div class="col">
              <div
                class="
                  arrow arrow-box
                  d-flex
                  align-items-center
                  justify-content-center
                  mt-2
                "
              >
                <a
                  @click.prevent="changeSpaceShips('prev')"
                  class="cursor-pointer arrow-left"
                >
                  <font-awesome-icon :icon="['fa', 'chevron-left']" />
                </a>
                <a
                  @click.prevent="changeSpaceShips('next')"
                  class="cursor-pointer arrow-right"
                >
                  <font-awesome-icon :icon="['fa', 'chevron-right']" />
                </a>
              </div>
            </div>
          </div>
        </main>

        <div v-else>
          <div class="container">
            <div class="row align-items-center">
              <div class="col mb-2 mb-md-0">
                <b-skeleton
                  animation="wave"
                  width="40%"
                  height="60px"
                ></b-skeleton>
                <b-skeleton
                  animation="wave"
                  width="30%"
                  class="mt-1"
                ></b-skeleton>
              </div>

              <div class="col-md-4 d-md-flex flex-column align-items-end">
                <b-skeleton
                  animation="wave"
                  width="80%"
                  height="35px"
                  class="mb-2"
                ></b-skeleton>
                <b-skeleton
                  animation="wave"
                  width="80%"
                  height="35px"
                  class="mb-2"
                ></b-skeleton>
                <b-skeleton
                  animation="wave"
                  width="80%"
                  height="35px"
                  class="mb-2"
                ></b-skeleton>
                <b-skeleton
                  animation="wave"
                  width="80%"
                  height="35px"
                ></b-skeleton>
              </div>
            </div>
          </div>
        </div>

        <Footer isSmall />

        <!-- Parallax -->
        <Parallax
          en="SPACESHIPS"
          id="PESAWAT LUAR ANGKASA"
          title="SPACESHIPS"
        />
      </div>
    </section>

    <!-- Modal -->
    <b-modal
      id="modal-space-ships"
      size="lg"
      centered
      hide-header
      hide-footer
      scrollable
    >
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-4">
            <a
              @click.prevent="closeModal"
              class="modal-close d-flex align-items-center"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2"
              >
                <path
                  d="M15 1L1 15M1 1L15 15"
                  stroke="#101010"
                  stroke-width="2.4"
                />
              </svg>
              <span data-en="CLOSE" data-id="TUTUP">CLOSE</span>
            </a>
          </div>
          <div class="col-8 text-right">
            <h5
              :data-en="modalData.title_en"
              :data-id="modalData.title_id"
              class="text-uppercase m-0"
            >
              {{ modalData.title_en }}
            </h5>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 mb-3">
            <div class="img">
              <img :src="`${urlImg}${modalData.img}`" :alt="modalData.title" />
            </div>
          </div>

          <div class="col-md-12">
            <h3 class="font-semi-bold">0{{ spaceShipIndex + 1 }}.</h3>
            <div
              :data-en="modalData.body_en"
              :data-id="modalData.body_id"
              v-html="modalData.body_en"
              class="body"
            ></div>
          </div>
        </div>
      </div>
    </b-modal>

    <div ref="bigNumber" class="d-none d-md-block big-number">01</div>

    <!-- Toggle Mobile -->
    <ToggleMobile />
  </div>
</template>

<script>
// Library
import { directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

// Layouts
import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";
import Footer from "@/components/layouts/Footer.vue";

// Components
import Parallax from "@/components/components/Parallax.vue";
import ToggleMobile from "@/components/components/ToggleMobile.vue";

// Services
import initParallax from "@/services/parallax/InitParallax.js";
import { getSpaceShips } from "@/services/admin/SpaceShips.js";
import { initLanguage } from "@/services/Language.js";

export default {
  name: "SpaceShips",
  metaInfo: {
    title: "Space Ships - Space Walk",
    description:
      "Space Walk is a website that is intended for people who have interests and talents in astronomy, with some basic knowledge, it is hoped that many people will start to be interested in astronomy.",
  },
  components: {
    Navbar,
    Sidebar,
    Footer,
    Parallax,
    ToggleMobile,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_STORAGE_URL,
      swiperSpaceShipsOptions: {
        // loop: true,
        grabCursor: true,
        centeredSlides: true,
        speed: 500,
        slidesPerView: 1,
      },
      spaceShips: [],
      spaceShipIndex: 0,
      modalData: {
        title_en: "",
        title_id: "",
        img: "",
        body_en: "",
        body_id: "",
      },
    };
  },
  mounted() {
    initParallax();
    this.getData();
  },
  methods: {
    changeSpaceShips(direction) {
      const swiper = this.$refs.spaceShipsSwiper.swiper;
      let index = swiper.realIndex;

      if (direction == "prev") {
        if (index == 0) {
          swiper.slideTo(this.spaceShips.length - 1);
        } else {
          swiper.slidePrev();
        }
      } else {
        if (index == this.spaceShips.length - 1) {
          swiper.slideTo(0);
        } else {
          swiper.slideNext();
        }
      }
    },
    spaceShipChanged(swiper) {
      const bigNumber = this.$refs.bigNumber;
      bigNumber.classList.add("active");
      this.spaceShipIndex = swiper.realIndex;

      setTimeout(() => {
        bigNumber.classList.remove("active");
      }, 250);

      const sliders = document.querySelectorAll(".swiper-slide .name");
      sliders.forEach((slider) => {
        slider.classList.remove("active");
      });

      this.setName();
      bigNumber.textContent = `0${this.spaceShipIndex + 1}`;
    },
    setName() {
      document
        .querySelector(".swiper-slide-active .name")
        .classList.add("active");
    },
    openModal(i, key, key_id) {
      this.modalData = {
        title_en: key,
        title_id: key_id,
        img: this.spaceShips[i].image,
        body_en: this.spaceShips[i][key + "_en"],
        body_id: this.spaceShips[i][key + "_id"],
      };

      setTimeout(() => {
        initLanguage();
      }, 100);

      this.$bvModal.show("modal-space-ships");
    },
    closeModal() {
      this.$bvModal.hide("modal-space-ships");
    },
    getData() {
      getSpaceShips().then((response) => {
        this.spaceShips = response.data.body.data.ships;

        setTimeout(() => {
          initLanguage();
          this.setName();
        }, 100);
      });
    },
  },
};
</script>