import axios from "axios";
import headers from "./Headers";

function getSpaceShips() {
  return axios.get("ships");
}

function createSpaceShip(form) {
  headers.contentType = "multipart/form-data";
  return axios.post("ships", form, headers);
}

function showSpaceShip(id) {
  return axios.get(`ships/${id}`, headers);
}

function editSpaceShip(id, form) {
  headers.contentType = "multipart/form-data";
  return axios.patch(`ships/${id}`, form, headers);
}

function deleteSpaceShip(id) {
  return axios.delete(`ships/${id}`, headers);
}

export {
  getSpaceShips,
  createSpaceShip,
  showSpaceShip,
  editSpaceShip,
  deleteSpaceShip,
};
